import { Config } from './types';

const config: Config = {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://pumproom-api.inzhenerka-cloud.com',
    apiTimeout: 0,
    appName: "PumpRoom-UI",
    appVersion: process.env.REACT_APP_VERSION || "0.0.0",
    appEnvironment: process.env.REACT_APP_ENVIRONMENT === "production" ? "production" : "development",
    taskSubmissionTimeout: 5 * 60 * 1000, // 5 minutes
    taskCheckStatusInterval: 1000, // in ms
    supportUrl: 'https://t.me/inzhenerkatech_support',
    allowedOrigins: ['https://inzhenerka.tech', 'https://inzhenerka-cloud.com', '.inzhenerka.tech', '.inzhenerka-cloud.com'],
    features: {
        profile: '1.1',
        state: '1.1',
        explain: '1.1',
    },
};

export default config;
