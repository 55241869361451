import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    CircularProgress,
    Avatar,
    TextField,
    Tooltip,
    Paper,
    InputAdornment,
    Typography,
} from '@mui/material';
import { indigo, green, red, grey } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import ReactMarkdown from 'react-markdown';
import useChatPrompt from '../hooks/useChatPrompt';
import { PumpingTask, TaskFile, TaskSubmissionOutput } from '../types';

interface GPTChatProps {
    taskSubmission: TaskSubmissionOutput | undefined;
    loadingResult: boolean;
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<
        React.SetStateAction<PumpingTask | undefined>
    >;
    applyTask: (selectedFile: string | null) => Promise<void>;
}

const GPTChat: React.FC<GPTChatProps> = ({
    taskSubmission,
    loadingResult,
    currentTask,
    setCurrentTask,
    applyTask,
}) => {
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [textError, setTextError] = useState<boolean>(false);
    const [placeholder, setPlaceholder] = useState<string | undefined>(
        undefined
    );
    const [chatPrompt, setChatPrompt] = useState<string | undefined>(undefined);
    const [shouldSendMessage, setShouldSendMessage] = useState(false);

    const receivedChatPrompt = useChatPrompt();

    useEffect(() => {
        if (receivedChatPrompt) {
            setChatPrompt(receivedChatPrompt);
            handleFileChange(receivedChatPrompt);
            setShouldSendMessage(true);
        }
    }, [receivedChatPrompt]);

    useEffect(() => {
        if (shouldSendMessage) {
            setShouldSendMessage(false);
            handleSendMessage();
        }
    }, [currentTask.files, shouldSendMessage]);

    useEffect(() => {
        if (currentTask.files.length > 0) {
            const starterFile: TaskFile | undefined = currentTask.files.find(
                (file) => file.path === 'starter.txt'
            );
            const placeholderFile: TaskFile | undefined =
                currentTask.files.find(
                    (file) => file.path === 'placeholder.txt'
                );
            if (starterFile) {
                setSelectedFile(starterFile.path);
                setChatPrompt(starterFile.content);
            }
            if (placeholderFile) {
                setPlaceholder(placeholderFile.content);
            }
        }
    }, [currentTask, setSelectedFile, selectedFile]);

    const getSelectedTaskFile = (): TaskFile | undefined => {
        if (!currentTask.files) {
            return;
        }
        return currentTask.files.find((file) => file.path === selectedFile);
    };

    const handleSendMessage = async () => {
        const selectedTaskFile = getSelectedTaskFile();
        if (!selectedTaskFile?.content.trim()) {
            setTextError(true);
            return;
        }
        setTextError(false);
        await applyTask(selectedFile);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.ctrlKey && event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handleFileChange = (value: string) => {
        setTextError(false);
        if (!currentTask.files) {
            return;
        }
        const updatedFiles = currentTask.files.map((file) => {
            if (file.path === selectedFile) {
                return { ...file, content: value };
            }
            return file;
        });
        setCurrentTask({ ...currentTask, files: updatedFiles });
    };

    const messagesLib = {
        teacher: {
            inputLabel: 'Write your message',
            emptyInputError: 'Message cannot be empty',
            resultPlaceholder: 'I’m ready to review',
        },
        chat: {
            inputLabel: 'Напиши свой запрос',
            emptyInputError: 'Запрос не может быть пустым',
            resultPlaceholder: 'Чем могу помочь?',
        },
    };

    var resultColor: string = grey[50];
    var messages = messagesLib.chat;
    if (currentTask?.framework === 'teacher') {
        if (taskSubmission) {
            if (taskSubmission.status === 'success') {
                resultColor = green[50];
            } else {
                resultColor = red[50];
            }
        }
        messages = messagesLib.teacher;
    }
    return (
        <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={2}
            py={2}
            height='100vh'
            width='100%'
        >
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={1}
                width='100%'
            >
                <Box display='flex' alignItems='center' width='100%' gap={2}>
                    <TextField
                        fullWidth
                        multiline
                        onKeyDown={handleKeyPress}
                        variant='outlined'
                        label={messages.inputLabel}
                        value={chatPrompt || ''}
                        onChange={(e) => handleFileChange(e.target.value)}
                        error={textError}
                        helperText={textError ? messages.emptyInputError : ''}
                        minRows={3}
                        maxRows={5}
                        sx={{ fontSize: 14 }}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        sx={{ alignSelf: 'flex-end' }}
                                    >
                                        <Box
                                            display='flex'
                                            flexDirection='column'
                                            alignItems='center'
                                            marginBottom={4}
                                        >
                                            <Typography
                                                variant='caption'
                                                sx={{
                                                    color: 'gray',
                                                    marginBottom: 1,
                                                }}
                                            >
                                                Ctrl + Enter
                                            </Typography>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                endIcon={
                                                    <SendIcon
                                                        sx={{ marginRight: 1 }}
                                                    />
                                                }
                                                onClick={handleSendMessage}
                                                fullWidth
                                                sx={{
                                                    minHeight: 48,
                                                    borderRadius: 2,
                                                }}
                                            ></Button>
                                        </Box>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                </Box>
            </Box>
            <Box
                display='flex'
                alignItems='center'
                width='100%'
                gap={1}
                flexGrow={1}
                overflow='hidden'
            >
                <Tooltip title='Doudou AI' placement='left'>
                    <Avatar
                        alt='Doudou AI'
                        sx={{
                            width: 60,
                            height: 60,
                            bgcolor: indigo[50],
                            alignSelf: 'flex-start',
                        }}
                        src='/doudou.png'
                    />
                </Tooltip>
                <Paper
                    variant='outlined'
                    sx={{
                        height: '100%',
                        overflowY: 'auto',
                        paddingX: 1,
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        minHeight: 150,
                        width: '100%',
                        backgroundColor: resultColor,
                    }}
                >
                    {loadingResult ? (
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            height='100%'
                        >
                            <CircularProgress
                                sx={{ marginTop: 8 }}
                                color='secondary'
                            />
                        </Box>
                    ) : (
                        <ReactMarkdown>
                            {taskSubmission?.message ||
                                placeholder ||
                                messages.resultPlaceholder}
                        </ReactMarkdown>
                    )}
                </Paper>
            </Box>
        </Box>
    );
};

export default GPTChat;
