import React from 'react';
import { Stack, Button } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import useEnvironment from '../hooks/useEnvironment';
import { isFeatureAvailable } from '../lib/features';
import { getTaskQueryParams } from '../lib/queryParams';
import { PumpingTask, TaskSubmissionOutput } from '../types';

interface ActionsProps {
    currentTask: PumpingTask;
    taskSubmission: TaskSubmissionOutput | undefined;
    setOpenResetTaskDialog: React.Dispatch<React.SetStateAction<boolean>>;
    postChatPrompt: (chatPrompt: string) => void;
    canRunCode: boolean;
    onRunCode: () => Promise<void>;
}

const Actions: React.FC<ActionsProps> = ({
    currentTask,
    taskSubmission,
    setOpenResetTaskDialog,
    postChatPrompt,
    canRunCode,
    onRunCode,
}) => {
    const environment = useEnvironment();
    const explainAvailable =
        isFeatureAvailable(environment.snippetVersion, 'explain') &&
        !getTaskQueryParams().noExplain;

    const handleOpenDialog = () => {
        setOpenResetTaskDialog(true);
    };

    return (
        <>
            <Stack
                id='actions'
                direction='row'
                spacing={2}
                className='actions'
                sx={{
                    flexDirection: { xs: 'row-reverse', md: 'row' },
                    flexWrap: 'wrap',
                    gap: { xs: '10px', md: '16px' },
                    paddingLeft: { xs: '10px', md: '25px' },
                }}
            >
                <Button
                    disabled={!canRunCode}
                    sx={{
                        minWidth: '150px',
                    }}
                    size='small'
                    variant='contained'
                    onClick={onRunCode}
                >
                    {currentTask?.framework === 'playwright'
                        ? '> npx playwright test'
                        : currentTask?.framework === 'maven'
                        ? '> mvn test'
                        : currentTask?.framework === 'rustc'
                        ? '> rustc main.rs && ./main'
                        : currentTask?.framework === 'python_multi'
                        ? '> python main.py'
                        : currentTask?.framework === 'cargo_test'
                        ? '> cargo test'
                        : currentTask?.framework === 'dbt'
                        ? '> dbt build'
                        : 'Запустить'}
                </Button>
                <Button
                    sx={{
                        marginLeft: '0px !important',
                    }}
                    size='small'
                    variant='outlined'
                    onClick={handleOpenDialog}
                >
                    Сбросить код
                </Button>
                {explainAvailable &&
                    taskSubmission?.explain_prompt &&
                    taskSubmission?.ready && (
                        <Button
                            sx={{
                                marginLeft: '0px !important',
                            }}
                            size='small'
                            variant='outlined'
                            startIcon={<AutoAwesomeIcon />}
                            onClick={() =>
                                postChatPrompt(
                                    taskSubmission.explain_prompt || ''
                                )
                            }
                        >
                            Объяснить
                        </Button>
                    )}
            </Stack>
        </>
    );
};

export default Actions;
